/* eslint-disable jsx-a11y/no-autofocus, react/jsx-pascal-case */
import FontAwesomeIcon from './Icon'
import React, { useState, createRef } from 'react'
import { Link } from 'gatsby'
import format from 'date-fns/format'
import {
  connectSearchBox,
  InstantSearch,
  connectStateResults,
  Highlight,
  Hits,
  Snippet,
} from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'
import { Flex, Link as RLink, Box, Txt, Badge } from 'rendition'
import styled from 'styled-components'
import useOutsideClick from './useOutsideClick'

const SearchForm = styled.form`
  display: flex;
  padding: 20px 36px;
  border-bottom: ${(props) => (props.show ? `solid 1px #e8ebf2` : `none`)};
  align-items: center;
`

const SearchInput = styled.input`
  margin-left: 16px;
  width: 100%;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  color: #2a506f;
  border: none;

  &:focus {
    outline: none;
  }
`

const SearchBar = ({ refine, currentRefinement, show, onFocus }) => {
  return (
    <SearchForm show={show}>
      <Txt.span fontSize="24px" color="primary.main">
        <FontAwesomeIcon icon={['fas', 'search']} />
      </Txt.span>
      <SearchInput
        type="text"
        placeholder="Search"
        aria-label="Search"
        autoFocus
        onChange={(e) => refine(e.target.value)}
        value={currentRefinement}
        onFocus={onFocus}
      />
    </SearchForm>
  )
}

const ConnectedSearchBar = connectSearchBox(SearchBar)

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits
  const hits = `${hitCount} result${hitCount !== 1 ? `s` : ``}`
  return hitCount > 0 ? <Badge>{hits}</Badge> : null
})

const PageHit = ({ hit }) => {
  return (
    <Flex
      justifyContent="space-between"
      bg="#fff"
      py={3}
      style={{ borderBottom: '1px dashed #e8ebf2' }}
    >
      <Flex flexDirection="column">
        <RLink
          color="#2a506f"
          style={{ fontSize: 16, fontWeight: 600 }}
          is={Link}
          to={hit.slug}
        >
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </RLink>
        <Snippet attribute="excerpt" hit={hit} tagName="mark" />
      </Flex>
      <Txt.span whitespace="nowrap" pl={4}>
        on {format(new Date(hit.updated_at), 'MMM dd, YYY')}
      </Txt.span>
    </Flex>
  )
}

const StyledHits = styled(Hits)`
  & > ul {
    list-style: none;
    padding: 0px 60px;
  }
`

const HitsInIndex = () => (
  <Box>
    <Box ml="60px" pt={3}>
      <HitCount />
    </Box>
    <StyledHits hitComponent={PageHit} />
  </Box>
)
const SearchResult = ({ indices, show }) => (
  <Box display={show ? 'block' : 'none'}>
    {indices.map((index) => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </Box>
)

const SearchContainer = styled(Box)`
  border-radius: 10px;
  background-color: #ffffff;
  max-width: 990px;
  margin-top: 200px;
`

const Backdrop = styled(Flex)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(42 80 111 / 30%);
  z-index: 10;
  overflow: scroll;
`

const Search = (props) => {
  const [query, setQuery] = useState('')
  const [hasFocus, setFocus] = useState(false)

  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )
  const rootRef = createRef()
  useOutsideClick(rootRef, () => {
    props.onClose()
  })
  return (
    <>
      <Backdrop
        flexDirection="column"
        alignItems="center"
        justifyItems="center"
      >
        <SearchContainer ref={rootRef} width={[990]}>
          <InstantSearch
            searchClient={searchClient}
            indexName={'Blogs'}
            onSearchStateChange={({ query }) => setQuery(query)}
          >
            <ConnectedSearchBar
              show={query && query.length > 0 && hasFocus}
              onFocus={() => setFocus(true)}
            />
            <SearchResult
              show={query && query.length > 0 && hasFocus}
              indices={[{ name: 'Blogs', title: 'Blogs' }]}
            />
          </InstantSearch>
        </SearchContainer>
      </Backdrop>
    </>
  )
}

export { Search as SearchBar }