export const palette = {
  cerulian: '#00AEEF', // primary
  sanJuan: '#2A506F', // font dark
  kashmirBlue: '#527699', // font light
  whiteLilac: '#F8F9FD', // background gray
  aliceBlue: '#EFF8FF', // background blue
  athensGray: '#e9ebf2',

  wildSand: '#F6F6F6',
  dustyGray: '#979797',
  malachite: '#1AC135',
  shipCove: '#749cc0',
  blueHaze: '#c1c7dd',
  frenchPass: '#c1e4fc',

  dangerCritical: '#ff0404',
  dangerMajor: '#ff9f00',
  dangerMinor: '#ffec02',
  success: '#7ee811',

  cloud: '#ffc100',
  open: '#00d2ea',
  os: '#00cc99',
  engine: '#eb407a',
  fin: '#ab47bc',
  etcher: '#231f20',

  etcherGreen: '#a5de37',
}
