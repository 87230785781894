import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql, Link } from 'gatsby'
import { Button } from 'rendition'

import { palette } from '../utils/colors'
import FontAwesomeIcon from './Icon'

const StatusButton = styled(Button)`
  > .dot {
    content: ' ';
    height: 10px;
    width: 10px;
    border: 2px solid #fff;
    border-radius: 100%;
    margin-right: 8px;

    &.none {
      background: ${palette.success};
    }
    &.critical {
      background: ${palette.dangerCritical};
    }
    &.major {
      background: ${palette.dangerMajor};
    }
    &.minor {
      background: ${palette.dangerMinor};
    }
  }
`

class ServiceStatusButton extends PureComponent {
  state = {
    description: '',
    indicator: '',
    intervalId: null,
  }

  componentDidMount() {
    const intervalId = setInterval(this.checkStatusPage, 1000)
    this.setState({ intervalId: intervalId })
  }

  componentWillUnmount() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId)
    }
  }

  checkStatusPage = () => {
    if (typeof window !== 'undefined' && window && window.StatusPage) {
      const { statusPageId } = this.props
      const sp = new window.StatusPage.page({
        page: statusPageId,
      })
      sp.summary({
        success: (data) =>
          this.setState({ ...data.status }, () =>
            clearInterval(this.state.intervalId)
          ),
      })
    }
  }

  render() {
    return (
      <Link href="https://status.balena.io/" ext>
        <StatusButton secondary>
          {!this.state.description ? (
            <FontAwesomeIcon icon={['fas', 'cog']} spin />
          ) : (
            <>
              {' '}
              <span className={`dot ${this.state.indicator}`} />
              {this.state.description}
            </>
          )}
        </StatusButton>
      </Link>
    )
  }
}

const ServiceStatus = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            statusPageId
          }
        }
      }
    `}
    render={(data) => (
      <ServiceStatusButton statusPageId={data.site.siteMetadata.statusPageId} />
    )}
  />
)

export default ServiceStatus
