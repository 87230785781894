import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { StaticQuery, graphql } from 'gatsby'
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Txt,
  Heading,
  Input,
  Link as RenditionLink,
} from 'rendition'
import ServiceStatus from './ServiceStatus'
import SmallPrintFooter from './SmallPrintFooter'
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faTwitch } from '@fortawesome/free-brands-svg-icons/faTwitch'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faSlideshare } from '@fortawesome/free-brands-svg-icons/faSlideshare'

import { palette } from '../utils/colors'
import { faDesktop } from '@fortawesome/free-solid-svg-icons/faDesktop'
import { faLaptop } from '@fortawesome/free-solid-svg-icons/faLaptop'
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Row = styled(Flex)`
  margin: 0 -16px;
`

export const FlexCol = styled(Flex)`
  padding: 0 16px;
`
const icons = {
  facebook: faFacebook,
  github: faGithub,
  twitter: faTwitter,
  instagram: faInstagram,
  linkedin: faLinkedin,
  youtube: faYoutube,
  twitch: faTwitch,
  slideshare: faSlideshare,
  desktop: faDesktop,
  laptop: faLaptop,
  book: faBook,
}
const SocialIconsRow = styled(Row)`
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 75em) {
    justify-content: center;
  }
`

const getNewsletter = (mailchimpForm) => (
  <form
    method="POST"
    target="_blank"
    action={`//${mailchimpForm.host}/subscribe/post?u=${mailchimpForm.u}&amp;id=${mailchimpForm.newsletterId}`}
  >
    <Heading.h6 mb={16} fontSize={13}>
      Subscribe to our newsletter
    </Heading.h6>
    <Flex mb={20}>
      <Input
        emphasized
        type="text"
        name="EMAIL"
        placeholder="Email"
        bg="#fff"
        width="100%"
      />
    </Flex>
    <Button type="submit" primary width="100%">
      Subscribe now
    </Button>
    <div style={{ display: 'none' }}>
      <input
        type="text"
        name={`b_${mailchimpForm.u}_${mailchimpForm.newsletterId}`}
        tabIndex="-1"
      />
    </div>
  </form>
)

const Footer = (props) => {
  return (
    <StaticQuery
      query={footerQuery}
      render={(data) => {
        const siteUrl = _.get(data, 'site.siteMetadata.siteUrl')
        const socialLinks = _.get(data, 'allFooterSocialJson.edges', [])
        const navigationLinks = _.get(data, 'allFooterLinksJson.edges', [])

        const mailchimpForm = _.get(data, 'site.siteMetadata.mailchimpForm', {})

        return (
          <Box>
            <Box bg={palette.whiteLilac}>
              <Container px={[3, 3, 3, 0]} pt={4}>
                <SocialIconsRow color={palette.sanJuan}>
                  {_.map(socialLinks, ({ node: medium }) => {
                    const mediumPath =
                      medium.needsPrefix && medium.isExternal
                        ? `${siteUrl}${medium.path}`
                        : medium.path
                    return (
                      <FlexCol my={2} key={medium.title}>
                        <RenditionLink href={mediumPath}>
                          <FontAwesomeIcon
                            icon={icons[medium.icon]}
                            size="1x"
                            color={palette.cerulian}
                          />
                          <Txt.span
                            color={palette.cerulian}
                            underline
                            fontSize={13}
                            ext={medium.isExternal}
                            ml={2}
                          >
                            {medium.title}
                          </Txt.span>
                        </RenditionLink>
                      </FlexCol>
                    )
                  })}
                </SocialIconsRow>
              </Container>

              <Divider my={4} color={'#fff'} height={1} />

              <Container px={[3, 3, 3, 0]} pb={50}>
                <Row flexWrap="wrap" mx={-36}>
                  <Flex
                    width={[1, 1 / 2, 1 / 2, 1 / 4]}
                    px={36}
                    order={[2, 1, 1, 0]}
                    justifyContent={[
                      'center',
                      'center',
                      'center',
                      'flex-start',
                    ]}
                  >
                    <Box mb={3}>
                      <ServiceStatus />
                    </Box>
                  </Flex>
                  <Flex
                    width={[1, 1, 1, 1 / 2]}
                    order={[0, 0, 0, 1]}
                    justifyContent={[
                      'space-around',
                      'space-around',
                      'space-around',
                      'space-between',
                    ]}
                    color={palette.sanJuan}
                    px={36}
                    flexWrap="wrap"
                  >
                    {navigationLinks.map(({ node: section }, i) => {
                      return (
                        <Txt
                          key={i}
                          mb={4}
                          align={['center', 'left']}
                          width={[1 / 2, 'auto']}
                        >
                          <Heading.h6 mb={16} fontSize={13}>
                            {section.category}
                          </Heading.h6>
                          {section.entries.map((child, i) => {
                            const childPath =
                              child.needsPrefix && child.isExternal
                                ? `${siteUrl}${child.path}`
                                : child.path

                            return (
                              <Box key={i} mb={10}>
                                <RenditionLink
                                  href={childPath}
                                  ext={child.isExternal}
                                >
                                  <Txt.span
                                    color={palette.kashmirBlue}
                                    underline
                                    fontSize={13}
                                  >
                                    {child.title}
                                  </Txt.span>
                                </RenditionLink>
                              </Box>
                            )
                          })}
                        </Txt>
                      )
                    })}
                  </Flex>
                  <Flex
                    width={[1, 1 / 2, 1 / 2, 1 / 4]}
                    order={[1, 2, 2, 2]}
                    color={palette.sanJuan}
                    justifyContent={['center', 'center', 'center', 'flex-end']}
                    px={36}
                    mb={4}
                  >
                    <Box width={['100%', '100%', '75%', '100%']}>
                      {getNewsletter(mailchimpForm)}
                    </Box>
                  </Flex>
                </Row>
              </Container>
            </Box>
            <SmallPrintFooter />
          </Box>
        )
      }}
    />
  )
}

const footerQuery = graphql`
  {
    site {
      siteMetadata {
        siteUrl
        mailchimpForm {
          u
          host
          newsletterId
          kitraId
        }
      }
    }
    allFooterSocialJson {
      edges {
        node {
          title
          icon
          path
          isExternal
        }
      }
    }
    allFooterLinksJson {
      edges {
        node {
          category
          entries {
            title
            path
            isExternal
          }
        }
      }
    }
  }
`

export default Footer
