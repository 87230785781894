import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/balenaBlog.svg'
import {
  Box,
  Container,
  Img,
  Link as RenditionLink,
  Flex,
  Txt,
  Button,
  Fixed,
} from 'rendition'
import styled, { css } from 'styled-components'
import NavDropDown from './NavDropDown'

import FontAwesomeIcon from './Icon'

export const invertBreakpoint = (breakpoint) => {
  return breakpoint - 0.01
}

export const Wrapper = styled.nav`
  position: relative;
  width: 100%;
  background: white;
  z-index: 5;

  @media (max-width: ${(props) =>
      invertBreakpoint(props.theme.breakpoints[1])}px) {
    position: sticky;
    background: white;
    top: 0;
    box-shadow: 0px 0px 21px 5px rgba(152, 173, 227, 0.2);

    & + div:first-of-type {
      margin-top: 80px;
    }
  }
`

export const Navbar = styled(Flex)`
  min-height: 80px;
  position: relative;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${(props) =>
      invertBreakpoint(props.theme.breakpoints[1])}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: auto;
    padding-top: 16px;
    padding-bottom: 16px;
  }
`

const Actions = styled(Box)`
  justify-content: flex-end;
  align-items: center;
`

const MobileActions = styled(Box)`
  display: none;
  min-height: 84px;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: ${(props) =>
      invertBreakpoint(props.theme.breakpoints[1])}px) {
    display: flex;
    flex-direction: column;
  }
`

const NavigationItem = styled(Box)`
  margin: 0;

  a {
    margin: 0 !important;
    color: ${(props) => props.theme.colors.text.main};
    display: block !important;
    padding-top: 12px !important;
    padding-bottom: 10px !important;
    font-size: initial;

    &:hover:before {
      border: none;
    }
  }

  svg {
    float: right;
    transform: translateY(4px);

    path {
      fill: ${(props) => props.theme.colors.text.main};
    }
  }
`
const NavigationItems = styled(Flex)`
  @media (max-width: ${(props) =>
      invertBreakpoint(props.theme.breakpoints[1])}px) {
    position: fixed;
    background: white;
    top: 0;
    right: 0;
    width: 300px;
    bottom: 0;
    justify-content: flex-start;
    transform: translateX(100%);
    transition: transform 200ms ease-in-out;
    padding: 16px;
    overflow: auto;
    flex-wrap: nowrap;

    ${(props) =>
      props.menuOpen
        ? css`
            position: fixed;
            transform: translateX(0%);
          `
        : ''}
  }
`

const getNavigationRoutes = (routes) => {
  return routes.map((route, index) => {
    if (route['routes']) {
      return (
        <NavigationItem pl={[0, 0, 3, 3]} key={index} mx={15}>
          <NavDropDown title={route.title} showDividers={route.showDividers}>
            {getNavigationRoutes(route.routes)}
          </NavDropDown>
        </NavigationItem>
      )
    }

    return (
      <NavigationItem pl={[0, 0, 3, 3]} key={index} mx={[2, 2, 15]}>
        <RenditionLink
          is={Link}
          blank={route.blank || false}
          to={route.path}
          py={1}
          px={0}
          display="auto"
          color="text.main"
        >
          <Txt.span bold>{route.title}</Txt.span>
        </RenditionLink>
      </NavigationItem>
    )
  })
}
const Header = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false)

  const closeMenu = () => {
    setMobileMenuOpen(false)
  }
  const openMenu = () => {
    setMobileMenuOpen(true)
  }
  return (
    <Wrapper role="navigation" aria-label="main-navigation">
      <Container>
        <Navbar>
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Flex alignItems="center">
              <RenditionLink
                href="https://blog.balena.io"
                style={{ lineHeight: 1 }}
              >
                <Img alt="balena" src={logo} height="42" />
              </RenditionLink>
            </Flex>
            {mobileMenuOpen && (
              <Fixed
                top
                left
                bottom
                display={['block', 'block', 'none', 'none']}
                right
                bg="rgba(0, 0, 0, 0.25)"
                onClick={closeMenu}
              />
            )}
            <Flex>
              <NavigationItems
                menuOpen={mobileMenuOpen}
                flexDirection={['column', 'column', 'row', 'row']}
                flexWrap="wrap"
                alignItems={['flex-start', 'flex-start', 'center', 'center']}
              >
                {mobileMenuOpen && (
                  <Flex
                    display={['block', 'block', 'none', 'none']}
                    justifyContent="flex-end"
                  >
                    <Button
                      plain
                      underline={false}
                      p={1}
                      color="text.main"
                      icon={<FontAwesomeIcon icon={['fas', 'times']} />}
                      onClick={closeMenu}
                    />
                  </Flex>
                )}
                {getNavigationRoutes(props.routes)}

                {props.actions && (
                  <MobileActions
                    pt={3}
                    display={['flex', 'flex', 'none', 'none']}
                  >
                    {props.actions}
                  </MobileActions>
                )}
              </NavigationItems>
              {props.actions && (
                <Actions display={['none', 'none', 'flex', 'flex']} ml={3}>
                  {props.actions}
                </Actions>
              )}
            </Flex>
            <Box display={['block', 'block', 'none', 'none']}>
              <Button
                style={{ fontSize: 20 }}
                icon={<FontAwesomeIcon icon={['fas', 'bars']} />}
                plain
                onClick={openMenu}
              />
            </Box>
          </Flex>
        </Navbar>
      </Container>
    </Wrapper>
  )
}

Header.defaultProps = {
  routes: [],
}

export default Header
