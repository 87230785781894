import { darken, lighten } from 'polished'
import _ from 'lodash'

const quaternary = '#F8F9FD'

const gray = {
  light: '#F6F6F6',
  main: '#F8F9FD',
  dark: '#979797',
}

const text = '#2A506F'
const cloud = '#ffc100'
const open = '#00d2ea'
const os = '#00cc99'
const engine = '#eb407a'
const fin = '#ab47bc'
const etcher = '#a5de37'

const createShades = (color) => {
  if (_.isObject(color)) return color
  return {
    main: color,
    light: lighten(0.1, color),
    dark: darken(0.1, color),
  }
}

const defaultColors = {
  quaternary,
  gray,
  text,
  cloud,
  open,
  os,
  engine,
  fin,
  etcher,
}

const createColors = (colors) => {
  return _.reduce(
    colors,
    (acc, val, key) => {
      acc[key] = createShades(val)
      return acc
    },
    {}
  )
}

export const fontSizes = [12, 14, 16, 20, 24, 34, 48, 64, 72, 96]
export const weights = [400, 600]

export const radius = 4
export const monospace = "menlo, 'Ubuntu Mono', 'Courier New', monospace"

export const breakpoints = [576, 768, 992, 1200]

export const space = [0, 4, 8, 16, 36, 46, 128]

const colors = createColors({
  ...defaultColors,
})

export default {
  colors,
  breakpoints,
  space,
  fontSizes,
  weights,
  monospace,
  radius,
  button: {
    font: {
      weight: '600',
      size: '14px',
    },
  },
}
