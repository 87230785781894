import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { faBookOpen } from '@fortawesome/free-solid-svg-icons'
import { faCodeBranch } from '@fortawesome/free-solid-svg-icons'
import { faTerminal } from '@fortawesome/free-solid-svg-icons'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import {
  faDumbbell,
  faDollarSign,
  faStopwatch,
} from '@fortawesome/free-solid-svg-icons'

config.autoAddCss = false

library.add(
  faSearch,
  faUser,
  faBookOpen,
  faCodeBranch,
  faTerminal,
  faDatabase,
  faLongArrowAltRight,
  faTimes,
  faBars,
  faChevronDown,
  faChevronUp,
  faEnvelope,
  faCog,
  faFacebookSquare,
  faTwitter,
  faDumbbell,
  faDollarSign,
  faStopwatch
)

export default FontAwesomeIcon
