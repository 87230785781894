import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Provider, Button, Link as RLink, Txt, Flex } from 'rendition'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
// import './all.sass';
import useSiteMetadata from './SiteMetadata'
import { withPrefix, Link } from 'gatsby'
import theme from './theme'
import { Normalize } from 'styled-normalize'
import { SearchBar } from './SearchBar'
import { createGlobalStyle } from 'styled-components'
import FontAwesomeIcon from './Icon'
import { palette } from '../utils/colors'

const GlobalStyle = createGlobalStyle`
 mark {
    background-color: #d6e9f8 !important;
    padding: 1px;
    color: #2a506f;
  }
`

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  const [searchBarOpen, setSearchBarOpen] = useState(false)
  return (
    <Provider theme={theme}>
      <>
        <Normalize />
        <GlobalStyle />
        <Helmet>
          <html lang="en" />
          <title>{title}</title>
          <meta name="description" content={description} />

          <meta http-equiv="Content-Type" content="text/html" charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta name="HandheldFriendly" content="True" />
          <meta name="MobileOptimized" content="320" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="referrer" content="origin" />
          <meta property="og:site_name" content="balena" />
          <meta
            property="article:publisher"
            content="https://www.facebook.com/balenacloud"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@balena_io" />

          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix('/')}img/favicon.ico`}
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix('/')}img/favicon.ico`}
            sizes="16x16"
          />

          <meta name="theme-color" content="#fff" />

          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={title} />
          <meta property="og:url" content="/" />
          <script async src="//cdn.statuspage.io/se-v2.js" />
        </Helmet>
        <Navbar
          actions={
            <React.Fragment key="action.navbar">
              <Flex
                bg="gray.main"
                alignItems="center"
                mr={5}
                px={3}
                py={2}
                my={[3, 3, 0, 0]}
                style={{ borderRadius: 20 }}
                minWidth={160}
                onClick={() => setSearchBarOpen(true)}
              >
                <Txt.span color="primary.main" fontSize={1} pr={2}>
                  <FontAwesomeIcon icon={['fas', 'search']} />
                </Txt.span>
                <Txt.span color={palette.blueHaze}>Search...</Txt.span>
              </Flex>
              <RLink is={Link} to="/tags/project" my={[3, 3, 0, 0]}>
                <Txt.span ml={[0, 0, 20, 20]} color="text.main" bold>
                  Projects
                </Txt.span>
              </RLink>
              <RLink
                target="_blank"
                href="https://dashboard.balena-cloud.com/signup"
              >
                <Button primary outline ml={[0, 0, 20, 20]} my={[3, 3, 0, 0]}>
                  Sign up
                </Button>
              </RLink>
            </React.Fragment>
          }
        />
        {searchBarOpen && <SearchBar onClose={() => setSearchBarOpen(false)} />}
        <div>{children}</div>
        <Footer />
      </>
    </Provider>
  )
}

export default TemplateWrapper
