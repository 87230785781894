import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Box, Flex, Txt, Link as RenditionLink } from 'rendition'

const links = [
  {
    path: 'https://balena.io/terms-of-service',
    title: 'Terms of Service',
  },
  {
    path: 'https://balena.io/privacy-policy',
    title: 'Privacy Statement',
  },
  {
    path: 'https://balena.io/master-agreement',
    title: 'Master agreement',
  },
]

const Divider = styled(Box).attrs({ mx: 2 })`
  border-left: 1px solid;
  height: 11px;
`

const SmallPrintFooter = ({ ...props }) => {
  return (
    <Flex
      bg="#fff"
      justifyContent="center"
      alignItems="center"
      p={20}
      flexWrap="wrap"
      {...props}
    >
      {links.map((link) => (
        <Fragment key={link.title}>
          <RenditionLink fontSize={13} href={link.path}>
            {link.title}
          </RenditionLink>
          <Divider />
        </Fragment>
      ))}

      <Txt fontSize={13}>Copyright {new Date().getFullYear()} Balena</Txt>
      <Divider />
      <Txt fontSize={13}>All Rights Reserved</Txt>
    </Flex>
  )
}

export default SmallPrintFooter
